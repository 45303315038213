export const state = {
    site: null,
    sites: null,
    users: [],
    tenant: null,
    tenants: null,
    tenantsName: null,
    devicegroup: null,
    devicegroups: null,
    devicegroupsName: null,
    sitesNames: null,
    entity: null,
    entities: null,
    nodeTests: [],
    siteNodes: [],
    siteProductList: [],
    siteLocationList: [],
    schedulesByDevice: [],
    serverBLEVersion: -1,
    /*
        nodeData is data pertaining to a node that persists siteNodes (as siteNodes is volatile due to
        being replaced everytime the site page is refreshed).
     */
    nodeData: {}, //Key = node id, value = dictionary of variables relating to the node,
    siteFaultSummary: [],
    siteUpcomingTests: [],
    siteTimezone: 'Australia/Sydney',
    perPage: null,
    defaultPerPage: 10, // Default value when no site-specific value exists

    // Persistent data for the deviceLogsComponent.
    deviceLogsComponent: {
        nodeId: null, // Node Id corresponding to the nodeLogs data.
        nodeLogs: [],
    },
    motionEvents: {
        rows: [],
        count: 0
    },
    batteryReplacementEvents: {
        rows: [],
        count: 0
    },
    powerEvents: {
        rows: [],
        count: 0
    },
    siteNodesList_UserControl: null,
}

export const nodeDefaultData = {
    hasTestRequest: false,
    hasCancelRequest: false,
}